import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import i18n from "i18n-react";
import { redirectToPlatform } from "./mainFunctions";

const SidebarLink = styled(Link)`
display: flex;
color: #e1e9fc;
justify-content: space-between;
align-items: center;
padding: 20px;
list-style: none;
height: 60px;
text-decoration: none;
font-size: 18px;

&:hover {
	background: #252831;
	border-left: 4px solid green;
	cursor: pointer;
}
`;

const SidebarLabel = styled.span`
margin-left: 16px;
`;

const DropdownLink = styled(Link)`
background: #252831;
height: 60px;
padding-left: 3rem;
display: flex;
align-items: center;
text-decoration: none;
color: #f5f5f5;
font-size: 18px;

&:hover {
	background: green;
	cursor: pointer;
}
`;

const SubMenu = ({ item }) => {

const [subNavByClick, setSubnavByClick] = useState(false);
let trueOrFalse = false;
if(item.id && item.id == "administrationNotification"){
	trueOrFalse = true;
}  

const [subnav, setSubnav] = useState(trueOrFalse);

const showSubnav = (e) => {
	let subItemForHide = document.querySelectorAll(".sc-gKXOVf");
	let subItemForHideArray = Array.from(subItemForHide);
	subItemForHideArray.forEach((a) =>{
		a.style.display = '';
	})
	if(e._reactName == "onMouseLeave" && !e.target.classList.contains("active") && e.target.classList.contains("sc-eCYdqJ")){
		subItemForHideArray.forEach((a) => {
			a.style.display = 'none';
		})
	}
	if(e._reactName == "onMouseDown" && !subNavByClick){
		setSubnavByClick(!subNavByClick)
	} 
	if(!subNavByClick && e._reactName != "onMouseDown"){
		setSubnav(!subnav);
	} 
} 


const handleClick = event => {
	if(!subNavByClick && !subnav){
		let subItemForHide = document.querySelectorAll(".sc-gKXOVf");
		let subItemForHideArray = Array.from(subItemForHide);
		subItemForHideArray.forEach((a) => {
			a.style.display = 'none';
		})
	}  
	let platformUrl = document.querySelector("#platformUrl").value;
	let pathRoot = document.querySelector("#pathRoot").value;
	let token = document.querySelector("#token").value;

	let goToPlatform = false;

	let html = "";
	
	if(event.target.id == "myAccountInformation" || event.target.id == "myAccountBilling" || event.target.id == "myAccountOperations" || 
	event.target.id == "administrationUsers" || event.target.id == "administrationAdministration" || 
	event.target.id == "administrationStablishments" || event.target.id == "administrationPermissions" || 
	event.target.id == "administrationConfiguration" || event.target.id == "administrationSecurity" || 
	event.target.id == "administrationNotification" || event.target.id == "administrationOperations" || 
	event.target.id == "administrationAudit" || event.target.id == "administrationIntegration"){
		goToPlatform = true;
		html = "dashboardMain.html";
	}else if(event.target.id == "mzatePlatform"){
		goToPlatform = true;
		html = "dashboard.html";
	} 
	
	if(goToPlatform){
		redirectToPlatform(platformUrl,pathRoot,token,html,event.target.id)
	}

	let subItem = document.querySelectorAll(".sc-gKXOVf");
	let arraysubItem = Array.from(subItem);
	let activosubItem = arraysubItem.find(cv => cv.classList.contains("active"));
	if (activosubItem) {
		activosubItem.classList.remove("active");
	} 

	let a = document.querySelectorAll(".sidebar2 a");
	let arrayA = Array.from(a);
  
	let activo = arrayA.find(cv => cv.classList.contains("active"));
	if (activo) {
	  activo.classList.remove("active");
	}
	event.currentTarget.classList.add('active');

	let span = document.querySelectorAll(".sidebar2 a span");
	let arrayS = Array.from(span);

	let sActivo = arrayS.find(s => s.classList.contains("active"));

	if (sActivo) {
		sActivo.classList.remove("active");
	}
	
	event.currentTarget.children[0].children[1].classList.add('active');

	if(event.currentTarget.nextElementSibling){
		event.currentTarget.nextElementSibling.classList.add('active');
		event.currentTarget.nextElementSibling.children[1].classList.add('active');
	} 

	let svg = document.querySelectorAll(".sidebar2 svg");

	for (let item of svg) {
		item.style.color = "rgb(255, 255, 255)";
	} 

	event.target.children[0].children[0].style.color = "#8DBE22";
};

const handleSubItem = event => {

	let platformUrl = document.querySelector("#platformUrl").value;
	let pathRoot = document.querySelector("#pathRoot").value;
	let token = document.querySelector("#token").value;

	let goToPlatform = false;

	let html = "";

	if(event.target.id == "administrationConfigurations" || event.target.id == "administrationWebAccess" || 
	event.target.id == "administrationBlocking" || event.target.id == "administrationReports" 
	){
		goToPlatform = true;
		html = "dashboardMain.html";
	}

	if(goToPlatform){
		redirectToPlatform(platformUrl,pathRoot,token,html,event.target.id)
	}

	let a = document.querySelectorAll(".sidebar2 a");
	let arrayA = Array.from(a);
  
	let activo = arrayA.find(cv => cv.classList.contains("active"));
	if (activo) {
	  activo.classList.remove("active");
	}
	
	let subItem = document.querySelectorAll(".sc-gKXOVf");
	let arraysubItem = Array.from(subItem);
	arraysubItem.forEach((a) =>{
		a.children[1].classList.remove("active")
	})
	let activosubItem = arraysubItem.find(cv => cv.classList.contains("active"));
	if (activosubItem) {
		activosubItem.classList.remove("active");
	}
	event.currentTarget.classList.add('active');
	event.currentTarget.children[1].classList.add('active')

	let svg = document.querySelectorAll(".sidebar2 svg");

	for (let item of svg) {
		item.style.color = "rgb(255, 255, 255)";
	}

	if(event.target.previousElementSibling.classList.contains("sc-gKXOVf")){
		event.target.previousElementSibling.previousElementSibling.classList.add('active');
		event.target.previousElementSibling.previousElementSibling.children[0].children[0].style.color = "#8DBE22";
		event.target.previousElementSibling.previousElementSibling.children[0].children[1].classList.add('active');
	}else{
		event.target.previousElementSibling.classList.add('active');
		event.target.previousElementSibling.children[0].children[0].style.color = "#8DBE22";
		event.target.previousElementSibling.children[0].children[1].classList.add('active');
	} 
	
	if(!subNavByClick){
		setSubnavByClick(!subNavByClick);
	} 
} 

return (
	<li onMouseEnter={item.subNav && showSubnav}  
	onMouseLeave={item.subNav &&  showSubnav}>
		<SidebarLink to={item.path}
		onMouseDown={item.subNav && showSubnav} onClick={handleClick} id={item.id}>
			<div>
			{item.icon}
			<SidebarLabel >{i18n.translate(item.title)}</SidebarLabel>
			</div>
			<div>
			{item.subNav && (subnav || subNavByClick)
				? item.iconOpened
				: item.subNav
				? item.iconClosed
				: null}
			</div>
		</SidebarLink>
		{subnav &&
			item.subNav.map((item, index) => {
			return (
				<DropdownLink to={item.path} key={index} onClick={handleSubItem} id={item.id}>
				{item.icon}
				<SidebarLabel>{i18n.translate(item.title)}</SidebarLabel>
				</DropdownLink>
			);
		})}
	</li>
);
};

export default SubMenu;
