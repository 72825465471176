import React from "react";
import { SidebarDataMzateAdministration } from "./SidebarDataMzateAdministration";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";
import FooterSidebar from "./FooterSidebar";

const SidebarMzateAdministration = () => {
	React.useEffect(() => {
		function handleResize() {
			let nav = document.querySelector("#nav-top").offsetHeight;
			let iframe = document.querySelector("iframe");
			let altura = window.innerHeight - nav;
			iframe.style.height = altura+"px";
		}
		window.addEventListener('resize', handleResize)
	})
	return (
		<IconContext.Provider value={{color:"#fff"}}>
			
			<ul className="ulSidebar" id="ulSidebar">
				{SidebarDataMzateAdministration.map((item, index) => {
					return <SubMenu item={item} key={index} />;
				})}
			</ul>
			<FooterSidebar url="AdministrationMzate"/>
			
		</IconContext.Provider>
	);
};

export default SidebarMzateAdministration;
