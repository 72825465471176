import React from "react";
import LogoContainer from "../components/logoContainer";
import { showSidebarCollapseFunction, hideSidebarCollapseFunction } from "../components/SidebarFunctions";
import ElectronicBillingIndex from "./electronicBillingContent/ElectronicBillingIndex"; 
import CreditNoteIndex from "./electronicBillingContent/CreditNoteIndex";
import InvoiceData from "./electronicBillingContent/InvoiceData";
import { useLocation } from 'react-router-dom';
import SidebarElectronicBilling from "../components/SidebarElectronicBilling";
import Products from "./electronicBillingContent/Products";
import PriceList from "./electronicBillingContent/PriceList";
import ImportProducts from "./electronicBillingContent/ImportProducts";
import PropTypes from 'prop-types';
import { windowHistoryReplaceState } from "../components/mainFunctions";
import ElectronicDocument from "./electronicBillingContent/ElectronicDocument"; 
import Configs from "./electronicBillingContent/Configs";

const ElectronicBilling = ({language,token, instanceType, independent}) => {
    var content = "";
    
    let url = useLocation().pathname;
    if(url === "/" || url === "/ElectronicBilling/ElectronicBillingIndex" || url === "/ElectronicBilling"){
        content = <ElectronicBillingIndex token={token} independent={independent} instanceType={instanceType} language={language} />;
    }else if(url === "/ElectronicBilling/CreditNote"){
        content = <CreditNoteIndex token={token} />;
    }else if(url === "/ElectronicBilling/Faq"){
        content = <InvoiceData token={token} />;
    }else if(url === "/ElectronicBilling/Products"){
        content = <Products token={token} />;
    }else if(url === "/ElectronicBilling/PriceList"){
        content = <PriceList token={token} />;
    }else if(url === "/ElectronicBilling/ImportProducts"){
        content = <ImportProducts token={token} />;
    }else if(url === "/ElectronicBilling/ElectronicDocument"){
        content = <ElectronicDocument token={token} language={language}/>
    }else if(url === "/ElectronicBilling/Configs"){
        content = <Configs token={token} />
    }   

    windowHistoryReplaceState(independent,instanceType);

    let flag = 0;
	const showSidebarCollapse = () => {
        
        if(document.querySelector(".sidebar2Collapse")){
            showSidebarCollapseFunction();
            flag = 1;
        }
	} 
    const hideSidebarCollapse = () => {
        if(flag == 1){
            hideSidebarCollapseFunction();
            flag = 0;
        } 
    }
    

    return (
        <div className="tab-pane fade" id="nav-controlElectronicBilling" role="tabpanel" aria-labelledby="nav-controlElectronicBilling-tab" style={{opacity: 1}}>
            <div className="sidebar2"  onMouseOver={showSidebarCollapse} onMouseOut={hideSidebarCollapse}>
                <LogoContainer/>
                <SidebarElectronicBilling/>
            </div>
            
            <div className="content" id="contentElectronicBilling">
                {content}
            </div>
        </div>
    );

} 

ElectronicBilling.propTypes = {
    token: PropTypes.string.isRequired, 
    independent: PropTypes.string.isRequired, 
    instanceType: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired
};

export default ElectronicBilling;