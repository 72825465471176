import React from "react";
import i18n from "i18n-react";
import DefaultModal from '../../components/DefaultModal';
import DefaultSpinner from "../../components/DefaultSpinner";
import 'bootstrap/dist/css/bootstrap.css';
import { configTemplateToUse, 
    validateConfigToCreate, 
    getValuesForSearchConfig,
    fetchConfigs, 
    processConfigs, 
    updateStateAfterFetch,
    handleError
    } from "../../components/mainFunctions"; 
import TemplateShowConfigs from "../templateConfigs/TemplateShowConfigs";
import AddContent from "../../img/add_content.png";

const url = process.env.REACT_APP_API_URL;

class Configs extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            configs:[],
            config: "",
            spinner: "",
            configNavContent:"",
            configIndexContent:"",
            modalContent:"",
            modalContent2:"",
            configurationPostSave:"",
            optionSelected: "",
            optionSelectedDescription:"", 
        }
         
    } 

    componentDidMount() {
        this.getFeConfigs();
    }

    getFeConfigs = (showNewConfigCreated,isSearch) => {
        document.querySelector(".divShowTable").style.display = "none";

        const stateUpdateIndex = {
            configIndexContent: "",
            spinner: <DefaultSpinner
                instanceType={document.querySelector("#instanceType").value}
                independent={document.querySelector("#independent").value}
            />
        };

        if (!isSearch) {
            stateUpdateIndex.configNavContent = "";
        }

        this.setState(stateUpdateIndex);

        let orgNodeId = "";
        let type = 0;
        let key = "";
        if (isSearch) {
            const result = getValuesForSearchConfig();
            orgNodeId = result.orgNodeId;
            type = result.type;
            key = this.state.optionSelected;
        }

        fetchConfigs(orgNodeId, type, key,'fe')
        .then(result => {
            const { selectOrgType, selectKeyConfig } = processConfigs(result, this.handleChange,'VISITS');
            updateStateAfterFetch(isSearch, result, this.setState.bind(this), selectOrgType, selectKeyConfig, this.prepareToShowConfig,this.getFeConfigs);

            if (showNewConfigCreated && this.state.configurationPostSave) {
                result.map(conf => {
                    if (conf.key === this.state.configurationPostSave.key) {
                        this.prepareToShowConfig(conf);
                        this.setState({ configurationPostSave: "" });
                    }
                });
            }
        })
        .catch(error => {
            handleError(error, this.setState.bind(this));
        });

    }; 

    handleChange = (option) => {
        this.setState({optionSelected:option.value})
    };

    prepareToShowConfig = (config) => {
        let divIndex = document.querySelectorAll(".divIndex");
        let arrayIndex = Array.from(divIndex);
        let arrayIndexItem = arrayIndex.map((s) => s.style.display = "none");
        let divShow = document.querySelectorAll(".divShow");
        let arrayShow = Array.from(divShow);
        let arrayShowItem = arrayShow.map((s) => s.style.display = "");
        document.querySelector(".divShowTable").style.display = "";
        this.setState({configIndexContent:"",config: config});
        setTimeout(() => {
            this.setState({configIndexContent:<TemplateShowConfigs config={config}/>});
        }, 10);
    } 

    addFeConfig = () => {
        this.state.config.create = true;
        this.state.config.update = false;
        this.setState({modalContent:""});
        let modalHtml = configTemplateToUse(this.state.config);
        let modalTitle = i18n.translate("newConfiguration");
        let buttons = [
            {text: i18n.translate("save"), function: this.saveNewConfig}, 
            {text: i18n.translate("default_close"), function: ""}
        ];
        setTimeout(()=> {
            this.setState({modalContent:<DefaultModal show={true} title={modalTitle}
            content={modalHtml} buttons={buttons}/>})
        }, 10);
    } 

    saveNewConfig = () => {
        validateConfigToCreate(this.state.config,this.setState.bind(this),this.getFeConfigs);
    }

    render(){
        return(
            <>
                {this.state.modalContent}
                {this.state.modalContent2}
                {this.state.configNavContent} 
                <br/>
                <div className='bodyContent' id='bodyContent'>
                    {this.state.spinner}
                    {this.state.configIndexContent}
                    <div className="divShowTable" style={{ display: 'none' }}>
                        <img className='addContent' src={AddContent} width="20px" height="20px" onClick={() => this.addFeConfig()} />
                    </div>
                </div>
            </>
        )
    } 
} 

export default Configs;