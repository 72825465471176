import React from "react";
import LogoContainer from "../components/logoContainer";
import SidebarTasks from "../components/SidebarTasks";
import Config from "./tasksContent/Config";
import { useLocation } from 'react-router-dom';
import TaskIndex from "./tasksContent/TaskIndex";
import { showSidebarCollapseFunction, hideSidebarCollapseFunction } from "../components/SidebarFunctions";
import PropTypes from 'prop-types';
import { windowHistoryReplaceState } from "../components/mainFunctions"; 

const Tasks = ({language,independent,instanceType}) =>{
    let content = "";
    
    let url = useLocation().pathname;
    if(url === "/" || url === "/Tareas/tareas" || url === "/Tareas"){
        content = <TaskIndex language={language} independent={independent} instanceType={instanceType}/>;
    }else if(url === "/Tareas/configuraciones"){
        content = <Config/>;
    }
     
    windowHistoryReplaceState(independent,instanceType);

    let flag = 0;
	const showSidebarCollapse = () => {
        
        if(document.querySelector(".sidebar2Collapse")){
            showSidebarCollapseFunction();
            flag = 1;
        }
	} 
    const hideSidebarCollapse = () => {
        if(flag == 1){
            hideSidebarCollapseFunction();
            flag = 0;
        } 
    }
 
    return (
        <div className="tab-pane fade" id="nav-controlTasks" role="tabpanel" aria-labelledby="nav-controlTasks-tab" style={{opacity: 1}}>
            <div className="sidebar2"  onMouseOver={showSidebarCollapse} onMouseOut={hideSidebarCollapse}>
                <LogoContainer/>
                <SidebarTasks/>
            </div>
            <div className="content" id="contentAdministration">
                {content}
            </div>
        </div>
    );
    
};

Tasks.propTypes = {
    language: PropTypes.string.isRequired,
    independent: PropTypes.string.isRequired,
    instanceType: PropTypes.string.isRequired
}; 

export default Tasks;
