import '../../App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as FaIcons from "react-icons/fa";
import { Table, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
import {  DynamicMap, ArrayString, IntervalDayAndHour, Boolean, ArrayOfMap } from "../templateConfigs/TemplateConfigs";
import AddContent from "../../img/add_content.png";
import BooleanTemplate from "../templateConfigs/BooleanTemplate";
import ArrayTemplate from "../templateConfigs/ArrayTemplate";
import DynamicMapTemplate from "../templateConfigs/DynamicMapTemplate";
import IntervalDayAndHourTemplate from '../templateConfigs/IntervalDayAndHourTemplate';
import ArrayOfMapTemplate from "../templateConfigs/ArrayOfMapTemplate";
import SelectGIE from '../../components/SelectGIE';
import TemplateShowConfigs from '../templateConfigs/TemplateShowConfigs';
import i18n from "i18n-react";
import Select from 'react-select';
import { getArrayOfMapValues } from '../templateConfigs/getArrayOfMap';
import { getDynamicMapValues } from '../templateConfigs/getDynamicMapValues';
import { getIntervalDayHourValues } from '../templateConfigs/getIntervalDayHourValues';
import { DefaultAjax, modalForbidden } from "../../components/DefaultAjax";
import {toastFunction,validateConfigToCreate} from "../../components/mainFunctions"; 


const url = process.env.REACT_APP_API_URL;

class Config extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            configs: [],
            config: [],
            html: "",
            modalInsertar: false,
            htmlModal: "",
            optionsKey: [],
            optionSelected:"",
            optionSelectedDescription:"",
            service: {
                isSupportImpersonation: false,
                assignedId: 0,
                key: "",
                type: 0,
                functionalityGroup: "task",
            },
            form: {
                key: '',
                functionalityGroup: '',
                companySettingType: '',
                value: '',
                assignedId: ''
            },
            selectKeyConfig:"",
            selectOrgType: "",
            configurationPostSave:""
        }
    }

    componentDidMount() {
        this.getConfigs();
        let nav = document.querySelector("#nav-top").offsetHeight;
        let navbar = document.querySelector(".navbar").offsetHeight;
        let altura = window.innerHeight - nav - navbar - 50;
        let bodyContent = document.querySelector("#bodyContent");
        bodyContent.style.maxHeight = altura + "px";
    }

    getConfigs = (showAll) => {
        this.setState({
            optionsKey:[],
            selectKeyConfig:"",
            selectOrgType:""
        });
        DefaultAjax('').get("companySetting/companySettings?isSupportImpersonation=false&assignedId=&key=&type=0&functionalityGroup=tasks")
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data)
                let optionsKey = [];
                this.setState({ configs: result });
                optionsKey.push({"value":"","label":i18n.translate("default_all")})
                for(let i = 0; i < result.length; i++){
                    optionsKey.push({"value":result[i].key,"label":i18n.translate(result[i].key)})
                }
                this.setState({
                    optionsKey:optionsKey,
                    selectKeyConfig: <Select onChange={this.handleChange} options={optionsKey}
                    defaultValue={{ label: optionsKey[0].label, value: optionsKey[0].value }}/>,
                    selectOrgType: <SelectGIE moduleCode={'VISITS'} classSelect={"col-sm-12 col-md-4 col-lg-4 col-xl-4 selectGIE"} classSelectType={"col-sm-12 col-md-4 col-lg-4 col-xl-4"} />
                })
                this.setState({html: ""});
                if(showAll){
                    if(this.state.configurationPostSave){
                        {result.map((conf) =>{
                            if(conf.key == this.state.configurationPostSave.key){
                                this.prepareToShow(conf);
                                this.setState({configurationPostSave:""});
                            } 
                        })}
                    } 
                     
                }
            }
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    }

    searchConfigs = () => {
        let orgNodeId,type;
        if(document.querySelector(".contentInput .selectType").value == "1"){
            orgNodeId = document.querySelector(".contentInput #profileSelected").value
        }else if(document.querySelector(".contentInput .selectType").value == "2"){
            orgNodeId = document.querySelector(".contentInput #groupSelected").value
        }else{
            orgNodeId = document.querySelector(".contentInput #userSelected").value;
        }
        type = document.querySelector(".contentInput .selectType").value;
        let key = this.state.optionSelected;
        
        DefaultAjax('').get("companySetting/companySettings?isSupportImpersonation=false&assignedId="+orgNodeId+"&key="+key+"&type="+type+"&functionalityGroup=tasks")
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data)
                this.setState({ configs: result });
                this.setState({html: ""});
            }
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    }


    indexConfig = (showAll) => {
        document.querySelector(".addContent").style.display = "";
        this.getConfigs(showAll);
        let divIndex = document.querySelectorAll(".divIndex");
        let arrayIndex = Array.from(divIndex);
        let arrayIndexItem = arrayIndex.map((s) => s.style.display = "inline-flex");
        let divShow = document.querySelectorAll(".divShow");
        let arrayShow = Array.from(divShow);
        let arrayShowItem = arrayShow.map((s) => s.style.display = "none");
        document.querySelector(".divIndexTable").style.display = "";
        document.querySelector(".divShowTable").style.display = "none";
    }

    addConfig = (show) => {
        this.state.config.update = false;
        this.setState({ modalInsertar: true });
        this.state.config.create = true;
        if (show) {
            this.state.config.update = true;
        }
        if (this.state.config.companySettingType == "DYNAMIC_MAP") {
            this.setState({ htmlModal: <DynamicMapTemplate array={this.state.config} /> });
        } else if (this.state.config.companySettingType == "ARRAY_STRING") {
            this.setState({ htmlModal: <ArrayTemplate array={this.state.config} /> });
        } else if (this.state.config.companySettingType == "INTERVAL_DAY_AND_HOUR") {
            this.setState({ htmlModal: <IntervalDayAndHourTemplate array={this.state.config} /> });
        } else if (this.state.config.companySettingType == "BOOLEAN") {
            this.setState({ htmlModal: <BooleanTemplate array={this.state.config} /> });
        } else if(this.state.config.companySettingType == "ARRAY_OF_MAP"){
            this.setState({ htmlModal: <ArrayOfMapTemplate array={this.state.config} /> });
        }
    }

    cerrarModalInsertar = () => {
        this.setState({ modalInsertar: false })
    }

    insertarConfiguracion = () => {
        let configuration = {
            key: "",
            value: "",
            companySettingType: "",
            functionalityGroup: "",
            tenantId: "",
            id: "",
            orgNodeId: "",
            type: "",
            username: "",
            fullname: "",
        };
        if (this.state.config.companySettingType == "BOOLEAN") {
            validateConfigToCreate(this.state.config,this.setState.bind(this),this.indexConfig)
        }else if (this.state.config.companySettingType == "ARRAY_STRING"){
            if (!this.state.config.update) {
                if(document.querySelector(".modalBody .selectType").value == ""){
                    this.alertSelectType();
                }else{
                    let valueString;
                    configuration.value = [];
                    let inputs = document.querySelectorAll(".arrayStringValue input");
                    for(let i = 0; i < inputs.length; i++){
                        configuration.value.push(inputs[i].value);
                    }
                    if(configuration.value.length > 0){
                        valueString = JSON.stringify(configuration.value);
                        configuration.value = valueString;
                        configuration = this.getConfigsValues(configuration);
                        if(configuration.orgNodeId == ""){
                            this.alertSelectType();
                        }else{
                            this.executeCreate(configuration);
                        }
                    }else{
                        this.showMessage();
                    } 
                    
                }
            }
        }else if(this.state.config.companySettingType == "DYNAMIC_MAP"){
            if (!this.state.config.update) {
                if(document.querySelector(".modalBody .selectType").value == ""){
                    this.alertSelectType();
                }else{
                    if(this.state.config.key == "RESCHEDULE_TASK"){
                        let valueString,valor;
                        configuration.value = {
                            "allowsRescheduling":{
                                "value":"false","type":"BOOLEAN"
                            },
                            "daysToAddWhenRescheduling":{
                                "value":"10","type":"int"
                            },
                            "tasks_to_do":{
                                "value":["Revisar","Presupuestar","Mantenimiento","Entrega"],"type":"ARRAY_STRING"
                            }
                        };
                        valor = configuration.value;
                        valor = getDynamicMapValues(valor);
    
                        valueString = JSON.stringify(valor);
                        configuration.value = valueString;
                        configuration = this.getConfigsValues(configuration);
                        if(configuration.orgNodeId == ""){
                            this.alertSelectType();
                        }else{
                            this.executeCreate(configuration);
                        }
                    }
                }
            }
        }else if(this.state.config.companySettingType == "INTERVAL_DAY_AND_HOUR"){
            if(document.querySelector(".modalBody .selectType").value == ""){
                this.alertSelectType();
            }else{
                let flag = 0;
                let trLength = document.querySelectorAll(".modalBody tr.trWeek").length;
                for(let i = 0; i < trLength; i++){
                    let tdIntervalTime = document.querySelectorAll(".timeInterval-"+i);
                    for(let k = 0; k < tdIntervalTime.length; k++){
                        if(tdIntervalTime[k+1]){
                            if(tdIntervalTime[k].children[0].value > tdIntervalTime[k+1].children[0].value){
                                flag = 1;
                                toastFunction(i18n.translate("end_must_be_greater"),"error");
                                break;
                            }
                        } 
                    }
                }
                if(flag == 0){
                    configuration.value = "";
                    let val = getIntervalDayHourValues();
                    if(val == ""){
                        this.showMessage();
                    }else{
                        configuration.value = val;
                        configuration = this.getConfigsValues(configuration);
                        if(configuration.orgNodeId == ""){
                            this.alertSelectType();
                        }else{
                            this.executeCreate(configuration);
                        }
                    } 
                } 
            } 
        }else if(this.state.config.companySettingType == "ARRAY_OF_MAP"){
            if(document.querySelector(".modalBody .selectType").value == ""){
                this.alertSelectType();
            }else{
                let values = getArrayOfMapValues(); 
                if(values.length > 0){
                    configuration.value = JSON.stringify(values);
                    configuration = this.getConfigsValues(configuration);
                    if(configuration.orgNodeId == ""){
                        this.alertSelectType();
                    }else{
                        this.executeCreate(configuration);
                    }
                }else{
                    this.showMessage();
                }  
            } 
        }
    }

    prepareToShow = (config)  => {
        let template = "";
        let divIndex = document.querySelectorAll(".divIndex");
        let arrayIndex = Array.from(divIndex);
        let arrayIndexItem = arrayIndex.map((s) => s.style.display = "none");
        let divShow = document.querySelectorAll(".divShow");
        let arrayShow = Array.from(divShow);
        let arrayShowItem = arrayShow.map((s) => s.style.display = "");
        document.querySelector(".divIndexTable").style.display = "none";
        document.querySelector(".divShowTable").style.display = "";
        this.setState({ config: config });
        
        template = <TemplateShowConfigs config={config}/>
        this.setState({ html: template });
        if (config.key == "PROFILES_THAT_CAN_CREATE_TASKS") {
            document.querySelector(".addContent").style.display = "none";
        }
    }

    alertSelectType = () => {
        toastFunction(i18n.translate("select_org_node"),"warning");
    }

    executeCreate = (configuration) => {
        if(configuration.value == "" || configuration.value == undefined 
        || configuration.value == null){
            toastFunction(i18n.translate("value_cannot_be_empty"),"error");
        }else{
            DefaultAjax('').post("companySetting/companySetting",configuration)
            .then((res) => {
                if(res){
                    let result = JSON.parse(res.data);
                    if(result == 0 || result == "0"){
                        toastFunction(i18n.translate("configuration_created"),"success");
                        this.state.config.show = false;
                        this.setState({html: ""});
                        this.indexConfig('showAll');
                        this.setState({modalInsertar: false});
                    }else{
                        toastFunction(i18n.translate("an_error_has_ocurred"),"error");
                    }
                }
            }).catch((error) =>{
                this.setState({
                    modalContent:"",
                    spinner:"",
                    component:error.message
                });
                if (error.response) {
                    if(error.response.status === 403){
                        setTimeout(()=> { 
                            this.setState({modalContent:modalForbidden()});
                        }, 10);
                    } 
                }
            });
        }  
    }

    showMessage = () =>{
        toastFunction(i18n.translate("add_configuration"),"error");
    } 

    getTypeSelect = () =>{
        let configurationOrgNodeId;
        if(document.querySelector(".modalBody .selectType").value == "1"){
            configurationOrgNodeId = document.querySelector(".modalBody #profileSelected").value;
            this.setState({optionSelectedDescription:document.querySelector(".modalBody #profileSelectedDescription").value})
        }else if(document.querySelector(".modalBody .selectType").value == "2"){
            configurationOrgNodeId = document.querySelector(".modalBody #groupSelected").value;
            this.setState({optionSelectedDescription:document.querySelector(".modalBody #groupSelectedDescription").value})
        }else{
            configurationOrgNodeId = document.querySelector(".modalBody #userSelected").value;
            this.setState({optionSelectedDescription:document.querySelector(".modalBody #userSelectedDescription").value})
        }
        return configurationOrgNodeId;
    } 

    getConfigsValues = (configuration) =>{
        configuration.key = this.state.config.key;
        configuration.functionalityGroup = this.state.config.functionalityGroup;
        configuration.id = null;
        configuration.companySettingType = this.state.config.companySettingType;
        configuration.tenantId = this.state.config.detail[0].tenantId;
        configuration.orgNodeId = this.getTypeSelect();
        configuration.type = document.querySelector(".modalBody .selectType").value;
        configuration.username = "username";
        configuration.fullname = "fullname";
        return configuration;
    } 

    handleChange = (option) => {
        this.setState({optionSelected:option.value})
    };

    render() {

        return (
            <div>
                <br />
                <nav className='navbar'>
                    <div className='contentInput row divIndex' style={{ width: '40%'}}>
                        <div style={{ display: 'inline-block' }} className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <label>{i18n.translate("description")}</label>
                            {this.state.selectKeyConfig}
                        </div>
                        {' '}
                        {this.state.selectOrgType} 
                    </div>
                    <div style={{ paddingTop: "22px" }}>
                        <div style={{ display: 'inline-block' }} className="divIndex" onClick={() => this.searchConfigs()}>
                            <button className='buttonMzateSecondary'>
                                <FaIcons.FaSearch />
                                <span>{i18n.translate("default_search")}</span>
                            </button>
                        </div>
                        {' '}
                        <div style={{ display: 'inline-block' }} className="divIndex">
                            <button className='buttonMzateSecondary' onClick={() => this.indexConfig("showAll")}>
                                <FaIcons.FaSearch />
                                <span>{i18n.translate("default_show_all")}</span>
                            </button>
                        </div>
                        <div style={{ display: 'none' }} className="divShow">
                            <button className='buttonMzateSecondary' onClick={() => this.indexConfig("showAll")}>
                                <FaIcons.FaLongArrowAltLeft />
                                <span>{i18n.translate("default_back")}</span>
                            </button>
                        </div>
                    </div>
                </nav>
                <br />
                <div className='bodyContent' id='bodyContent'>
                    <Table className='table table-hover divIndexTable'>
                        <tbody>
                            <tr>
                                <th className='firstcell'>{i18n.translate("description")}</th>
                                <th className='cellgroup'>{i18n.translate("values")}</th>
                            </tr>
                            {this.state.configs.map((elemento) => (
                                <tr className='cellOptions' onClick={() => this.prepareToShow(elemento)}>
                                    <td>
                                        {elemento.isVisible == false && <FaIcons.FaEyeSlash />}
                                        {i18n.translate(elemento.key)}
                                    </td>
                                    <td className='text cellgroup'>
                                            {elemento.companySettingType == "DYNAMIC_MAP" && 
                                            <DynamicMap array={elemento} />}
                                            {elemento.companySettingType == "ARRAY_STRING" && <ArrayString array={elemento} />}
                                            {elemento.companySettingType == "INTERVAL_DAY_AND_HOUR" && <IntervalDayAndHour array={elemento} />}
                                            {elemento.companySettingType == "BOOLEAN" && <Boolean array={elemento} />}
                                            {elemento.companySettingType == "ARRAY_OF_MAP" && <ArrayOfMap array={elemento} />}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <div className="divShowTable" style={{ display: 'none' }}>
                        {this.state.html}
                        <img className='addContent' src={AddContent} width="20px" height="20px" onClick={() => this.addConfig()} />
                    </div>
                    <Modal isOpen={this.state.modalInsertar}>
                        <ModalHeader className='modalHeader'>
                            {i18n.translate("newConfiguration")}
                            <button className='close' data-dismiss="close" onClick={() => this.cerrarModalInsertar()}>x</button>
                        </ModalHeader>
                        <ModalBody className='modalBody' >
                            {this.state.htmlModal}
                        </ModalBody>
                        <ModalFooter>
                            <button className="buttonMzatePrimary" onClick={() => this.insertarConfiguracion()}>{i18n.translate("save")}</button>
                            <button className="buttonMzateSecondary" onClick={() => this.cerrarModalInsertar()}>{i18n.translate("cancel")}</button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        )
    }
}

export default Config;