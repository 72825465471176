export const TopbarData = (rolesToHaveUser) => {
    return usersRoles(rolesToHaveUser);
}

export const TopbarDataCollapse = (rolesToHaveUser) => {
    
    return  usersRoles(rolesToHaveUser);
}

export const usersRoles = (rolesToHaveUser) => {
    const roleSet = new Set(rolesToHaveUser);
    const roleFlags = createRoleFlags(roleSet);
    const modules = [];

    addMainModules(modules, roleFlags);
    addAdministrativeModules(modules, roleFlags);

    return modules;
}

const createRoleFlags = (roleSet) => ({
    crm: roleSet.has("ROLE_MP_CRM"),
    platMzaSuport: roleSet.has("ROLE_PLATFORM_MZATE_SUPPORT"),
    platAdmin: roleSet.has("ROLE_PLATFORM_ADMIN"),
    platSuport: roleSet.has("ROLE_PLATFORM_SUPPORT"),
    geofences: roleSet.has("ROLE_MP_GEOFENCING"),
    visits: roleSet.has("ROLE_MP_VISITS"),
    orders: roleSet.has("ROLE_MP_ORDERS"),
    routes: roleSet.has("ROLE_MP_ROUTES"),
    proVisits: roleSet.has("ROLE_MP_PROFESSIONAL_VISITS"),
    proPlaning: roleSet.has("ROLE_MP_PROFESSIONAL_PLANNING"),
    admin: roleSet.has("ROLE_CMP_ADMIN"),
    castadmin: roleSet.has("ROLE_CMP_CASTADMIN"),
    supervisor: roleSet.has("ROLE_MP_SUPERVISOR"),
    tracking: roleSet.has("ROLE_MP_TRACKING"),
    task: roleSet.has("ROLE_MP_TASK"),
    electronicBilling: roleSet.has("ROLE_MP_FE"),
    controlPanel: roleSet.has("controlPanel"),
    mzateSupport: roleSet.has("mzateSupport"),
    logSupport: roleSet.has("logSupport")
});

const addMainModules = (modules, roleFlags) => {
    modules.push({title: "tutorial_videos", path: "/Videos", className: "displayNone", id: "tutorialVideos"});
    if (roleFlags.geofences) modules.push({ title: "geofences_title", path: "/Geofences", id: "geofencesPlatform" });
    if (roleFlags.crm) modules.push({ title: "opportunities_title", path: "/Oporttunities", id: "opportunitiesPlatform" });
    if (roleFlags.visits) modules.push({ title: "visits_title", path: "/Visits", id: "visitsPlatform" });
    if (roleFlags.task) modules.push({ title: "tasks_title", path: "/Tareas" });
    if (roleFlags.orders) modules.push({ title: "orders_title", path: "/Orders", id: "ordersPlatform" });
    if (roleFlags.electronicBilling) modules.push({ title: "default_billing", path: "/ElectronicBilling" });
    if (roleFlags.routes && (roleFlags.admin || roleFlags.supervisor)) modules.push({ title: "routes_title", path: "/Routes", id: "routesPlatform" });
    if (roleFlags.proVisits) modules.push({ title: "professionalVisits_title", path: "/ProfessionalV", id: "professionalVisitsPlatform" });
    if (roleFlags.proPlaning && (roleFlags.admin || roleFlags.supervisor)) modules.push({ title: "professionalManagement_title", path: "/ManagementP", id: "professionalManagementPlatform" });
}

const addAdministrativeModules = (modules, roleFlags) => {
    if (roleFlags.castadmin || 
        roleFlags.controlPanel || 
        (roleFlags.platMzaSuport && roleFlags.logSupport && !roleFlags.mzateSupport) || 
        ((roleFlags.admin || roleFlags.supervisor || roleFlags.platAdmin) && !roleFlags.tracking)) {
        modules.push({ title: "controlPanel_title", path: "/Panel", id: "controlPanelPlatform" });
    }

    if (roleFlags.admin || roleFlags.castadmin || 
        !roleFlags.mzateSupport || 
        (roleFlags.platMzaSuport && roleFlags.logSupport && !roleFlags.controlPanel) || 
        roleFlags.platAdmin) {
        modules.push({title: "administration_title", path: "/AdministrationMzate", id: "administrationPlatform"});
    }

    if (roleFlags.castadmin || 
        (roleFlags.platMzaSuport && roleFlags.logSupport && isTrue(roleFlags.mzateSupport) && !isFalse(roleFlags.controlPanel)) || 
        roleFlags.platSuport || 
        roleFlags.platAdmin) {
        modules.push({title: "default_support", path: "/Support", id: "supportPlatform"});
    }
    
    if (roleFlags.admin) {
        modules.push({title: "myAccount_title", path: "/MyAccount", id: "myAccountPlatform"});
    }
}

const isTrue = (value) => value === true || value === "true";

const isFalse = (value) => value === false || value === "false";
